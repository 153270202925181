import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useSiteMetadata } from 'hooks/useSiteMetaData';

const SEO = ({ title, description, pathname, children }) => {
    const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata();
    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
    };

    return (
        <Helmet>
            <title>Lorema ipsum hub</title>
            <meta content="Lorem ipsum hub - Lorem ipsum fot the anyone." name="description" />
            <meta content={seo.image} name="image" />
            <link href="https://www.loremipsumhub.com" rel="canonical" />
            <meta content={seo.image} name="twitter:card" />
            <meta content={seo.title} name="twitter:title" />
            <meta content={seo.url} name="twitter:url" />
            <meta content={seo.description} name="twitter:description" />
            <meta content={seo.image} name="twitter:image" />
            <meta content={seo.twitterUsername} name="twitter:creator" />

            {/* <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'SoftwareApplication',
                    name: 'Lorema',
                    operatingSystem: 'MacOS',
                    applicationCategory: ['DesignApplication', 'DeveloperApplication'],
                })}
            </script> */}
            {children}
        </Helmet>
    );
};

SEO.propTypes = {
    children: PropTypes.any,
    description: PropTypes.any,
    pathname: PropTypes.any,
    title: PropTypes.any,
};

export default SEO;
