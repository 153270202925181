import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { COLORS, COLOR_MODE_KEY, IMAGES, INITIAL_COLOR_MODE_CSS_PROP } from './config/constants';

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
    const [colorMode, rawSetColorMode] = useState(undefined);

    useEffect(() => {
        const root = window.document.documentElement;

        // Because colors matter so much for the initial page view, we're
        // doing a lot of the work in gatsby-ssr. That way it can happen before
        // the React component tree mounts.
        const initialColorValue = root.style.getPropertyValue(INITIAL_COLOR_MODE_CSS_PROP);

        rawSetColorMode(initialColorValue);
    }, []);

    const contextValue = useMemo(() => {
        function setColorMode(newValue) {
            const root = window.document.documentElement;

            localStorage.setItem(COLOR_MODE_KEY, newValue);

            Object.entries(COLORS).forEach(([name, colorByTheme]) => {
                const cssVarName = `--color-${name}`;

                root.style.setProperty(cssVarName, colorByTheme[newValue]);
            });

            Object.entries(IMAGES).forEach(([name, colorByTheme]) => {
                const cssVarName = `--image-${name}`;

                root.style.setProperty(cssVarName, colorByTheme[newValue]);
            });

            rawSetColorMode(newValue);
        }

        return {
            colorMode,
            setColorMode,
        };
    }, [colorMode, rawSetColorMode]);

    return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

ThemeProvider.propTypes = {
    children: PropTypes.node,
};
