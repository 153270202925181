import MenuBarDarkImage from 'assets/images/menubar_dark.png';
import MenuBarLightImage from 'assets/images/menubar_light.png';
import PreferencesDarkImage from 'assets/images/preferences_dark.png';
import PreferencesLightImage from 'assets/images/preferences_light.png';

export const COLORS = {
    text: {
        light: '#000',
        dark: '#ffffff',
    },
    background: {
        light: '#F5F7FA',
        dark: '#2E3A47',
    },
    secondaryBackground: {
        light: '#E4E7EB',
        dark: '#384655',
    },
    tertiaryBackground: {
        light: '#fff',
        dark: '#232D39',
    },
    errorColor: {
        light: '#673AB7',
        dark: '#F9DB6D',
    },
    selectionBackground: {
        light: '#F8F9FA',
        dark: '#2E3A47',
    },
    selectionColor: {
        light: '#FB5012',
        dark: '#F9DB6D',
    },
    link: {
        light: '#FB5012',
        dark: '#F9DB6D',
    },
};

export const IMAGES = {
    preferences: {
        light: `url(${PreferencesLightImage})`,
        dark: `url(${PreferencesDarkImage})`,
    },
    menubar: {
        light: `url(${MenuBarLightImage})`,
        dark: `url(${MenuBarDarkImage})`,
    },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
