import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const NavContext = React.createContext();

const NavProvider = ({ children }) => {
    const [activeNavLinkId, setActiveNavLinkId] = useState('');

    return (
        <NavContext.Provider
            value={{
                activeNavLinkId,
                setActiveNavLinkId,
            }}
        >
            {children}
        </NavContext.Provider>
    );
};

NavProvider.propTypes = {
    children: PropTypes.node,
};

export default NavProvider;
