import React from 'react';
import PropTypes from 'prop-types';
import { Analytics } from '@vercel/analytics/react';
import NavProvider from '../context/NavContext';
import Seo from './SEO';
import { ThemeProvider } from './ThemeContext';
import GlobalStyles from './styles/GlobalStyles';

const App = ({ children }) => (
    <ThemeProvider>
        <NavProvider>
            <GlobalStyles />
            <Analytics mode="production" />
            <Seo />
            {children}
        </NavProvider>
    </ThemeProvider>
);

App.propTypes = {
    children: PropTypes.node,
};

export default App;
