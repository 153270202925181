import { createGlobalStyle } from 'styled-components';
import { reset } from './reset.js';
import './fonts.css';

const GlobalStyles = createGlobalStyle`
  ${reset}
  
  :root {
  --font-lg: 18px;
  --font-md: 16px;
  --font-sm: 14px;
  --spacing-0px: 0px;
  --spacing-2px: 2px;
  --spacing-4px: 4px;
  --spacing-8px: 8px;
  --spacing-12px: 12px;
  --spacing-16px: 16px;
  --spacing-20px: 20px;
  --spacing-24px: 24px;
  --spacing-32px: 32px;
  --spacing-36px: 36px;
  --spacing-40px: 40px;
  --spacing-48px: 48px;
  --spacing-56px: 56px;
  --spacing-64px: 64px;
  --spacing-72px: 72px;
  --spacing-80px: 80px;
  --spacing-88px: 88px;
  --spacing-96px: 96px;
  --spacing-104px: 104px;
  --spacing-112px: 112px;
  --spacing-128px: 128px;
  --spacing-136px: 136px;
  --spacing-144px: 144px;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-twitter: #1D9BF0;
  --color-github: #CD25DA;
  --color-linkedin: #007dbb;
  --color-mail: #EA4335;
  --size-gutter: var(--space-5);
  --size-gap: var(--space-6);
  --grid-template: repeat(24, 1fr);

  ::-webkit-scrollbar {
    display: none;
  }
  
  
   
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  
  
}

  body {
    background: var(--color-background);
    color: var(--color-text);
    -webkit-font-smoothing: antialiased;
    font-family: "Roboto", sans-serif;
  }

  a {
    color: var(--color-link);
    text-decoration: none;
  }

  ::selection {
    color: var(--color-selectionColor);
    background: var(--color-selectionBackground);
  }




`;

export default GlobalStyles;
